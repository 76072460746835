// import downloadjs from 'downloadjs';
// import React from 'react';
import React, { useState, useEffect, useContext } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import './BookingHeader.css';
import { SHA256 } from "crypto-js"; // Import the MD5 function
import moment from "moment";
// const dotenv = require('dotenv');
// dotenv.config();


export const BookingHeader = ({
	booking_status,
	trip_id,
	historyData,
	amount,
	printTicket = null,
	ticketPDF,
	ticket_id,
	ticket_id_hash,
	editBooking = false,
	email
}) => {

	const EINVOICE_KEY = process.env.REACT_APP_KEY_SECRET;
	const ETICKET_ORDER_SUMMARY_URL = process.env.REACT_APP_ORDER_SUMMARY_URL;

	const print_ticket = async () => {
		if (ticketPDF != null && ticketPDF != "") {
			window.open(`http://res.causewaylink.com.my/pdf/${ticketPDF}`);
		}
		else if (ticket_id != null && ticket_id_hash != null) {
			window.open(`${ETICKET_ORDER_SUMMARY_URL}?ticket_id=${ticket_id}&key=${ticket_id_hash}`);
		}
		else {
			alert('Error while generate ticket, please try again later');
		}
		// try {
		//     const response = await fetch(`http://res.causewaylink.com.my/pdf/Ticket--ilmcehdw.xyr.pdf`);
		//     if (!response.ok) {
		//         throw new Error(
		//             `This is an HTTP error: The status is ${response.status}`
		//         );
		//     }
		//     let pdf = await response.json();
		//     downloadjs(response, {ticketPDF}, 'text/pdf');
		// } catch (err) {
		//     console.log(err)
		// }
	}

	function generateHashCode(extra_value: string[] | null = null): string {
		const today = moment().format("YYYYMMDD");
		let stringToHash;

		if (Array.isArray(extra_value) && extra_value.length > 0) {
			// Join the array elements into a single string
			const extraValueString = extra_value.join("");
			stringToHash = EINVOICE_KEY + extraValueString + today;
			console.log(`stringToHash=${stringToHash}`);
		}
		return SHA256(stringToHash).toString();
	}


	// Function to request e-invoice
	const request_einvoice = async () => {

		console.dir(historyData);

		if (
			!historyData ||
			!historyData.ordernumber ||
			!historyData.currency ||
			!historyData.totalamount
		) {
			console.error("Invalid history data provided.");
			return;
		}

		const hash = generateHashCode([
			historyData.ordernumber,
			historyData.currency,
			historyData.totalamount,
			"P005"
		]);

		const einvoiceUrl = `http://einvoice.handalindah.com.my/form?trans_id=${historyData.ordernumber}&currency=${historyData.currency}&amt=${historyData.totalamount}&project_id=P005&transaction_datetime=${historyData.purchasedate}&transaction_type=online&key=${hash}`;

		// Open the URL in a new window/tab
		window.open(einvoiceUrl, "_blank");
	};

	// console.log(trip_id)
	// console.log(historyData)
	// console.log(email)
	const statusIcon = booking_status == 'Confirmed' ? "images/ic_confirm.png" : "";
	// const EditBooking = editBooking != null ? editBooking : "";

	if (booking_status === 'Confirmed' && trip_id != null) {
		return (
			<>
				<div className='booking_header' id='test'>
					<Container>
						<Row className='flex-center'>
							<Col sm={printTicket == null ? 12 : 7}>

								{!editBooking && (
									<div className='booking_header_status'>
										<h5 className='status no-border'>
											Your Booking is <span className='booking-status'>{booking_status}</span>
											<img src={`${statusIcon}`} alt={booking_status} />
										</h5>
										<h6>
											Your Boarding Code is <strong>{trip_id}</strong>.
											{email && email !== 'null' ? (
												<> All details will be sent to <strong>{email}</strong></>
											) : ""}
										</h6>
									</div>
								)}

							</Col>
							<Col sm={5}>
								{
									printTicket != null ?
										<button
											className='printTicket'
											onClick={print_ticket}
										>
											<img src="images/ic_print.png" />&nbsp;{printTicket}&nbsp;
										</button>
										: ""
								}

								{
									historyData && (
										historyData.iseinvoiceeligible &&
											(historyData.einvoicestatus === null || historyData.einvoicestatus === "" || historyData.einvoicestatus === "Rejected") ? (
											<button
												className="printTicket mx-1"
												onClick={request_einvoice}
											>
												&nbsp;Request e-Invoice&nbsp;
											</button>
										) : (
											(historyData.einvoicestatus === "New" || historyData.einvoicestatus === "Completed") ? (
												<span className="eInvoiceButton mx-1">
													&nbsp;e-Invoice ({historyData.einvoicestatus})&nbsp;
												</span>
											) : ("")
										)
									)
								}



							</Col>

							{/* {
                                editBooking == true ?
                                <Col sm={3}>
                                    <LinkButton          
                                    buttonStyle="btn--print--ticket"
                                    buttonSize="btn--ticket"
                                    linkTo="/BookingEdit"
                                    icon="images/ic_edit.png"
                                    children='Edit Booking'
                                    >
                                    </LinkButton>
                                </Col>
                                :""
                            } */}
						</Row>
					</Container>
				</div>
			</>
		)
	}
	else if (booking_status == 'Pending Confirmation') {
		return (
			<>
				<div className='booking_header'>
					<Container>
						<Row className='flex-center'>
							<Col sm={12} lg={12}>
								<div className='booking_header_status'>
									<h5 className='status no-border'>Your Booking is <span className='booking-status-pending'>{booking_status}</span>
									</h5>
									<h6>Your booking seems to be taking longer than usual to confirm.
									</h6>
									<h6>
										Your booking is currently pending confirmation, and the processing time is approximately 0-15 minutes.  We will send all details to <strong>{email}</strong> once confirmation has been received.
									</h6>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		)
	}
	else return (
		<>
			<div className='booking_header'>
				<Container>
					<Row className='flex-center'>
						<Col sm={12} lg={12}>
							<div className='booking_header_status'>
								<h5 className='status'>Your Booking was <span className='booking-status-failed'>Unsuccessful{/*booking_status*/}</span>
								</h5>
							</div>
						</Col>

						{/* <Col sm={6} lg={3}>
                            <div className='booking_header_id'>
                                <h5>Booking ID: <span>{trip_id}</span></h5>
                            </div>
                        </Col>
                        <Col sm={6} lg={2}>
                            <div className='booking_header_amount'>
                                <h5>Total Paid: <span>RM {amount}</span></h5>

                            </div>
                        </Col>
                        <Col sm={6} lg={3}>
                            <LinkButton
                                className=""
                                buttonStyle="btn--print--ticket"
                                buttonSize="btn--ticket"
                                linkTo=""
                                icon="images/ic_print.png"
                                // children={printTicket}
                            >
                            </LinkButton>
                        </Col> */}
					</Row>
				</Container>
			</div>
		</>
	);
}

