import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import { getNextDay, checkIsReschedule, tokenIsValid, getToken } from '../Function';
import { LoadingScreenModal } from '../LoadingScreenModal';
import { SelectDepartureAndReturn } from '../SelectDepartureAndReturn';
// import Checkout from '../Checkout';
import { SelectDepartureHeader } from '../SelectDepartureHeader';


function PickDepart(props) {

	const SERVER_URL = process.env.REACT_APP_SERVER_URL;
	const checkReschedule = checkIsReschedule();

	const [origin_data, setOriginData] = useState(JSON.parse(sessionStorage.getItem("Origin_data")));
	const [destination_data, setDestinationData] = useState(JSON.parse(sessionStorage.getItem("Destination_data")));
	const [searchParams, setSearchParams] = useSearchParams();
	const [data, setData] = useState(null);
	const [dateDetail, setDateDetail] = useState(null);

	const currency = checkCurrency();
	const origin_subplace_unique_shortname = searchParams.get("depart");
	const destination_subplace_unique_shortname = searchParams.get("destination");

	var data_depart_date;

	if (searchParams.get("depart_date") === 'today') {
		// console.log('today');
		// Get today's date in yyyy-mm-dd format
		const today = new Date();
		const yyyy = today.getFullYear();
		const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so +1
		const dd = String(today.getDate()).padStart(2, '0');

		// Assign the formatted date to data_depart_date
		data_depart_date = `${yyyy}-${mm}-${dd}`;
	} else {
		data_depart_date = searchParams.get("depart_date") != null ? searchParams.get("depart_date") : origin_data.departdate;
	}


	const data_return_date = searchParams.get("return_date") != null ? searchParams.get("return_date") : origin_data.returndate;
	const data_passenger = searchParams.get("passenger") != null ? searchParams.get("passenger") : origin_data.passenger;

	const [selected_date, setSelectedDate] = useState(data_depart_date);
	const [loading, setLoading] = useState(true);

	function checkCurrency() {
		if (sessionStorage.getItem("Origin_data") != null) {
			const origin_data = JSON.parse(sessionStorage.getItem("Origin_data"));
			// console.log(origin_data)
			if (origin_data.placeid === 4) {
				return "SGD";
			}
			else {
				return "MYR";
			}
		}
	}

	// let getTripsUrl = `${SERVER_URL}/me/get/trips/${getNextDay(selected_date,-5)}/${getNextDay(selected_date,5)}/${currency}/${selected_date}/${destination_subplace_unique_shortname}/null/${origin_subplace_unique_shortname}/${data_passenger}`
	let getTripsUrl = `${SERVER_URL}/me/get/trips/${getNextDay(selected_date, -5)}/${getNextDay(selected_date, 5)}/${currency}/${selected_date}/${origin_subplace_unique_shortname}/null/${destination_subplace_unique_shortname}/${data_passenger}`

	if (checkReschedule === true) {
		const origin_data = sessionStorage.getItem("Origin_data") != null ? JSON.parse(sessionStorage.getItem("Origin_data")) : null;
		const destination_data = sessionStorage.getItem("Destination_data") != null ? JSON.parse(sessionStorage.getItem("Destination_data")) : null;

		getTripsUrl = `${SERVER_URL}/me/get/trips/${getNextDay(selected_date, -5)}/${getNextDay(selected_date, 5)}/${currency}/${selected_date}/${origin_data.subplaceuniqueshortname}/null/${destination_data.subplaceuniqueshortname}/${origin_data.passenger}`
	}

	const handleSelectedDateCallBack = (selected_date) => {
		setSelectedDate(selected_date)
	}

	useEffect(() => {
		setLoading(true);
		const getTripsData = async () => {
			try {
				const response = await fetch(getTripsUrl,
					{
						method: "POST",
						headers: {
							'Authorization': getToken()
						}
					});
				if (!response.ok) {
					throw new Error(
						`This is an HTTP error: The status is ${response.status}`
					);
				}
				let tripsData = await response.json();

				// console.log(tripsData)

				setData(tripsData.trips.trips);
				setDateDetail(tripsData.tripsummary);

				if (checkReschedule === false) {
					setOriginData(tripsData.origin)
					setDestinationData(tripsData.destination)
					sessionStorage.setItem("Origin_data", JSON.stringify(tripsData.origin));
					sessionStorage.setItem("Destination_data", JSON.stringify(tripsData.destination));
				}
				// if(depart.SubPlaceId !== tripsData.origin.SubPlaceId || destination.SubPlaceId !== tripsData.destination.SubPlaceId) {
				//     window.location.reload();
				// }
			} catch (err) {
				// console.log(err)
			} finally {
				setLoading(false);
			}
		}

		tokenIsValid()

		if (getToken() != null) {
			if (sessionStorage.getItem("Origin_data") != null) {
				const origin_data = JSON.parse(sessionStorage.getItem("Origin_data"));
				localStorage.setItem("currency", JSON.stringify("MYR"));
			}

			getTripsData()
		}

	}, [currency, selected_date, getToken()])

	if (origin_data != null) {
		return (
			<>
				{loading && <div className='center'>
					<LoadingScreenModal />
				</div>}
				<SelectDepartureHeader
					subplace_data={props.subplace_data}
					depart={origin_data}
					destination={destination_data}
					date_depart={data_depart_date}
					date_return={data_return_date}
					passenger={data_passenger}
					edit_search='Edit Search'
				>
				</SelectDepartureHeader>

				<SelectDepartureAndReturn
					title="Departure"
					link="/pickReturn"
					depart={origin_data}
					destination={destination_data}
					handleSelectedDateCallBack={handleSelectedDateCallBack}

					data={data}
					dateDetail={dateDetail}

					origin_subplace_unique_shortname={origin_subplace_unique_shortname}
					destination_subplace_unique_shortname={destination_subplace_unique_shortname}

					date_depart={data_depart_date}
					date_return={data_return_date}
					passenger={data_passenger}
				/>
			</>
		);
	}
}

export default PickDepart;
