import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import "../App.css";
import { ChildAmountContext } from '../UserContext';
import { ContentTitle } from "./ContentTitle";
import { tokenIsValid, getToken, getUserPrimaryEmail, changeDateFormatToYYYYMMDD, changeDateFormatToYearMonthDay, checkIsReschedule, getNationalityShortName } from './Function';
import "./PassengerDetails.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function PassengerDetails({ parentPassengerDetailCallback,
	// depart,
	// destination,
	depart_date,
	return_date,
	passenger_count,
	userData,
	reschedulePassengerDetails
}) {
	const SERVER_URL = process.env.REACT_APP_SERVER_URL;
	const { childAmount, setChildAmount } = useContext(ChildAmountContext);
	const [adultAmount, setAdultAmount] = useState(passenger_count);
	const [ticketType, setTicketType] = useState({});
	const [identityType, setIdentityType] = useState({});
	const [dob, setDob] = useState({});
	const [ppexp, setPPexp] = useState({});
	const checkReschedule = checkIsReschedule();
	const [FnFdata, setFnFData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [tmpIndex, setTmpIndex] = useState(null);
	const [tmpIC, setTmpIC] = useState('');
	const [tmpPP, setTmpPP] = useState('');
	const [tmpPPExp, setTmpPPExp] = useState('');

	useEffect(() => {
		// console.log(tmpIndex)
		// console.log(document.getElementById(`passport_${tmpIndex}`))
		if (tmpIndex !== null) {
			if (document.getElementById(`ic_${tmpIndex}`) != null) {
				document.getElementById(`ic_${tmpIndex}`).value = tmpIC
			}
			else if (document.getElementById(`passport_${tmpIndex}`) != null) {
				document.getElementById(`passport_${tmpIndex}`).value = tmpPP
				setPassportExpiryField(tmpIndex, new Date(tmpPPExp))
				// document.getElementById(`passport_expiry_date_${tmpIndex}`).value = tmpPPExp
			}
			setTmpIC('')
			setTmpPP('')
			setTmpPPExp('')
			setTmpIndex(null)
		}
	}, [identityType])

	// console.log(FnFdata)
	// console.log(tmpIndex)
	// console.log(document.getElementById(`passport_${tmpIndex}`))
	const updateSelectedDropdownClassList = (value, id) => {
		const selectedID = document.getElementById(id);
		if (value === "") {
			if (!selectedID.classList.contains("first-data")) {
				selectedID.classList.add("first-data")
			}
		}
		else {
			if (selectedID.classList.contains("first-data")) {
				selectedID.classList.remove("first-data")
			}
		}
	}


	const token = getToken();
	useEffect(() => {
		const getFnFData = async () => {
			try {
				const FnF_response = await fetch(
					`${SERVER_URL}/user/list/family/${getUserPrimaryEmail()}`,
					{
						headers: {
							'Authorization': token
						}
					}
				);
				if (!FnF_response.ok) {
					throw new Error(
						`This is an HTTP error: The status is ${FnF_response.status}`
					);
				}
				let FnF_Data = await FnF_response.json();
				setFnFData(FnF_Data);
				// console.log(FnF_Data)
			} catch (err) {
				setFnFData(null);
			} finally {
				setLoading(false);
			}
		}
		tokenIsValid()
		if (getUserPrimaryEmail() != null) {
			getFnFData()
		}
	}, [getToken()])



	const setDobField = (field, value) => {
		setDob({
			...dob,
			[field]: value
		})
	}

	const setPassportExpiryField = (field, value) => {
		setPPexp({
			...ppexp,
			[field]: value
		})
	}
	// console.log(reschedulePassengerDetails)
	useEffect(() => {
		if (checkReschedule == true) {
			let dobArr = [];
			let IdTypeObj = {};
			let PPExpObj = {};

			reschedulePassengerDetails.forEach((element, index) => {
				dobArr.push(new Date(element.PassengerDateOfBirth.split("T")[0]))
				// console.log(element.PassengerPassportExpiryDate)
				if (element.PassengerPassportExpiryDate != '' && element.PassengerPassportExpiryDate != null) {
					// setIdType(index,true)
					// setPassportExpiryField(index, new Date(element.PassengerPassportExpiryDate))
					IdTypeObj[index] = true
					PPExpObj[index] = new Date(element.PassengerPassportExpiryDate)
					// console.log(identityType)
				}

			});
			// console.log(IdTypeObj)
			// console.log(PPExpObj)
			setPPexp(PPExpObj)
			setIdentityType(IdTypeObj)
			setDob(dobArr)
		}
		else {
			if (userData != null) {
				if (userData.dob != null && userData.dob != "") {
					setDobField('0', new Date(userData.dob))
				}
				// console.log(userData)
				if (userData.passport != '' && userData.passportexpiry != '') {
					setIdType(0, true)
					setPassportExpiryField(0, new Date(userData.passportexpiry))
				}
				else {
					setIdType(0, false)
				}
			}
		}
	}, [])
	// checkReschedule==true?reschedulePassengerDetails[i].PassengerDateOfBirth.split("T")[0]:(i==0 && userData!= null) ? userData.dob != '' ? changeDateFormatToYearMonthDay(userData.dob) : "" : ""
	const [form, setForm] = useState({})
	// console.log(reschedulePassengerDetails)
	function checkChildAmount() {
		let childCount = 0;
		var arr = (Object.values(ticketType));
		arr.forEach(element => {
			if (element === 'C') {
				childCount++;
			}
		});
		return childCount;
	}
	// console.log(userData)

	useEffect(() => {
		const child = checkChildAmount();
		setChildAmount(child);
	}, [ticketType])



	const setField = (field, value) => {
		setTicketType({
			...ticketType,
			[field]: value
		})
	}

	const setIdType = (field, value) => {
		// console.log("Field=>" + field)
		// console.log("Value=>" + value)
		setIdentityType({
			...identityType,
			[field]: value
		})
	}
	// console.log(identityType);

	const handleChangeIdentityType = (event, i) => {
		if (event.target.value === 'IC') {
			setIdType(i, false)
			parentPassengerDetailCallback(event, '', 'passport', i)
			parentPassengerDetailCallback(event, '', 'passport_expiry_date', i)
		} else {
			setIdType(i, true)
			parentPassengerDetailCallback(event, '', 'ic', i)
		}
	}
	const updateChildAmount = (event, i) => {
		setField(i, event.target.value);
		// console.log(i);
	}
	// console.log(form);
	// console.log(ticketType.ticket0)
	// console.log("Child: " +childAmount)
	// console.log("Adult: " +adultAmount)

	let selected_depart_seat = sessionStorage.getItem("selectedDepartSeat");
	let selected_return_seat = sessionStorage.getItem("selectedReturnSeat");
	let array_depart_seat = eval(selected_depart_seat);//.split(",");
	let array_return_seat = eval(selected_return_seat);//.split(",");

	// console.log('---------------------');
	// console.dir((array_depart_seat));
	// console.log('---------------------');

	function autofill(event, value, field, i) {
		var fnf_name = document.getElementById(`guest_name_${i}`);
		var fnf_ticket_type = document.getElementById(`ticket_type_${i}`);
		var fnf_nationality = document.getElementById(`nationality_${i}`);
		var fnf_identity_type = document.getElementById(`identity_type_${i}`);
		// var fnf_passport = document.getElementById(`passport_${i}`);
		// var fnf_passport_expiry = document.getElementById(`passport_expiry_date_${i}`);
		// var fnf_ic = document.getElementById(`ic_${i}`);
		var fnf_datalist = document.getElementById('fnf')
		var flag = false;
		if (FnFdata != null) {
			for (let j = 0; j < FnFdata.length; j++) {
				flag = FnFdata[j].full_name === fnf_name.value || flag
				if (flag === true) {
					// console.log(j)
					// console.log(FnFdata[j].ic)
					parentPassengerDetailCallback(event, FnFdata[j].full_name, 'guest_name', i)
					parentPassengerDetailCallback(event, FnFdata[j].nationality, 'nationality', i)
					parentPassengerDetailCallback(event, FnFdata[j].ticket_type, 'ticket_type', i)

					fnf_ticket_type.value = FnFdata[j].ticket_type
					fnf_nationality.value = FnFdata[j].nationality
					if (FnFdata[j].ic != '' && FnFdata[j].ic != null) {
						setTmpIC(FnFdata[j].ic)
						setTmpIndex(i)
						fnf_identity_type.value = "IC"
						setIdType(i, false)
						parentPassengerDetailCallback(event, '', 'passport', i)
						parentPassengerDetailCallback(event, '', 'passport_expiry_date', i)
						parentPassengerDetailCallback(event, FnFdata[j].ic, 'ic', i)
						document.getElementById(`ic_${i}`).value = FnFdata[j].ic

					}
					else {
						// console.log(fnf_passport)
						setTmpPP(FnFdata[j].passport)
						setTmpPPExp(FnFdata[j].passport_expiry)
						setTmpIndex(i)
						fnf_identity_type.value = "PP"
						setIdType(i, true)
						// console.log(document.getElementById(`passport_${i}`))
						parentPassengerDetailCallback(event, '', 'ic', i)
						parentPassengerDetailCallback(event, FnFdata[j].passport, 'passport', i)
						parentPassengerDetailCallback(event, FnFdata[j].passport_expiry, 'passport_expiry_date', i)


						document.getElementById(`passport_${i}`).value = FnFdata[j].passport
						setPassportExpiryField(i, new Date(FnFdata[j].passport_expiry))
						// document.getElementById(`passport_expiry_date_${i}`).value = FnFdata[j].passport_expiry
					}
					break;
				}
			}
		}
		parentPassengerDetailCallback(event, value, field, i)
	}

	if (array_depart_seat.length == 0) {
		window.location.href = '/';
	}
	return (
		<>
			<div className="passenger_details pt-3">
				<ContentTitle
					title='Fill Passenger Details'
					description="Who's travelling?"
				/>
				<div className="passenger_form">
					<Form >
						<Form.Label>*all fields are mandatory</Form.Label>
						<datalist id="fnf">
							<select>
								{FnFdata && FnFdata.map(({ full_name }, index) => (
									<option key={index} value={full_name} />
								))}

								{/* {FnFdata && FnFdata.map(({ full_name }) => (
									<option value={full_name} />
								))} */}
							</select>
						</datalist>
						<section>
							{Array.from({ length: passenger_count }, (_, i) =>
								<>
									<Form.Group className="mt-1 mb-5" >
										<Row>
											<Col>
												<div className="text-line" >
													<span className="PassengerHeader">{(i == 0) ? 'Primary Passenger Information' : `Passenger Information ${i + 1}`}</span>
												</div>
												<span className="PassengerSeats">{`Depart ${array_depart_seat[i].DisplayCode} ${(array_return_seat[i] == null) ? '' : `| Return ${array_return_seat[i].DisplayCode}`}`}</span>
											</Col>
										</Row>

										<Row>
											<Col xs={6} sm={3} className="ticketCenter">
												<h6>Ticket Type: </h6>
											</Col>
											<Col xs={6} sm={9}>
												<Form.Select disabled={checkReschedule == true ? true : false} key={`ticket_type_${i}`} id={`ticket_type_${i}`} className={`mb-2 ticketType`} onChange={event => { parentPassengerDetailCallback(event, event.target.value, 'ticket_type', i); updateChildAmount(event, i); updateSelectedDropdownClassList(event.target.value, `ticket_type_${i}`) }} defaultValue={checkReschedule == true ? reschedulePassengerDetails[i].PassengerType : "A"}>
													<option value="">Ticket Type</option>
													<option value="A" selected>Adult</option>
													<option value="C">Child / OKU</option>
													{/* <option value="S">Senior Citizen</option>
                                                    <option value="O">OKU</option> */}
												</Form.Select>
											</Col>
										</Row>

										<Row>
											<Col sm={6}>
												<Form.Control disabled={checkReschedule == true ? true : false} autoComplete="off" key={`guest_name_${i}`} id={`guest_name_${i}`} list='fnf' className="fullname" type="text" placeholder="Full Name (as per passport/IC)" onChange={event => autofill(event, event.target.value, 'guest_name', i)} defaultValue={checkReschedule == true ? reschedulePassengerDetails[i].PassengerName : (i == 0 && userData != null) ? userData.fullname : ""} />
											</Col>
											<Col sm={3}>
												<Form.Select disabled={checkReschedule == true ? true : false} key={`gender_${i}`} id={`gender_${i}`} className={`mb-2 ${checkReschedule == true ? reschedulePassengerDetails[i].PasseengerGender : (i == 0 && userData !== null) ? "" : "first-data"}`}
													defaultValue={checkReschedule == true ? reschedulePassengerDetails[i].PasseengerGender : (i == 0 && userData !== null) ? userData.gender : ""} onChange={event => { parentPassengerDetailCallback(event, event.target.value, 'gender', i); updateSelectedDropdownClassList(event.target.value, `gender_${i}`) }}>
													<option value="">Gender</option>
													<option value="M">Male</option>
													<option value="F">Female</option>
												</Form.Select>
											</Col>
											<Col sm={3}>
												<DatePicker
													onChange={(date) => {
														parentPassengerDetailCallback(date, changeDateFormatToYYYYMMDD(date), 'dob', i);
														setDobField(i, date)
													}}
													selected={dob[i]}
													peekNextMonth
													showMonthDropdown
													showYearDropdown
													disabled={checkReschedule == true ? true : false}
													maxDate={new Date()}
													dropdownMode="select"
													placeholderText="Date Of Birth"
													dateFormat="yyyy-MM-dd"
													className='Date_Picker'
												/>
												{/* <Form.Control disabled={checkReschedule==true?true:false} key={`dob_${i}`} className="ms-0" type="text" placeholder="Date of Birth" maxlength='10' onChange={event => parentPassengerDetailCallback(event, event.target.value, 'dob', i)}  defaultValue={checkReschedule==true?reschedulePassengerDetails[i].PassengerDateOfBirth.split("T")[0]:(i==0 && userData!= null) ? userData.dob != '' ? changeDateFormatToYearMonthDay(userData.dob) : "" : ""}/> */}
												<Form.Label>(YYYY-MM-DD)</Form.Label>
											</Col>
										</Row>

										<Row>
											<Col sm={3}>
												<Form.Select disabled={checkReschedule == true ? true : false} key={`nationality_${i}`} id={`nationality_${i}`} className={`mb-2 ${checkReschedule == true ? getNationalityShortName(reschedulePassengerDetails[i].PasseengerNationality) : (i == 0 && userData !== null) ? "" : "first-data"}`}
													defaultValue={checkReschedule == true ? getNationalityShortName(reschedulePassengerDetails[i].PasseengerNationality) : (i == 0 && userData !== null) ? userData.nationality : ""} onChange={event => { parentPassengerDetailCallback(event, event.target.value, 'nationality', i); updateSelectedDropdownClassList(event.target.value, `nationality_${i}`) }}>
													<option value="">Nationality</option>

													<option value="MY">Malaysia</option>
													<option value="SG">Singapore</option>

													<option value="AF">Afghanistan</option>
													<option value="AL">Albania</option>
													<option value="DZ">Algeria</option>
													<option value="AD">Andorra</option>
													<option value="AO">Angola</option>
													<option value="AI">Anguilla</option>
													<option value="AG">Antigua and Barbuda</option>
													<option value="AR">Argentina</option>
													<option value="AM">Armenia</option>
													<option value="AW">Aruba</option>
													<option value="AU">Australia</option>
													<option value="AT">Austria</option>
													<option value="AZ">Azerbaijan</option>
													<option value="BS">Bahamas</option>
													<option value="BH">Bahrain</option>
													<option value="BD">Bangladesh</option>
													<option value="BB">Barbados</option>
													<option value="BY">Belarus</option>
													<option value="BE">Belgium</option>
													<option value="BZ">Belize</option>
													<option value="BJ">Benin</option>
													<option value="BM">Bermuda</option>
													<option value="BT">Bhutan</option>
													<option value="BO">Bolivia</option>
													<option value="BA">Bosnia and Herzegovina</option>
													<option value="BW">Botswana</option>
													<option value="BR">Brazil</option>
													<option value="VG">British Virgin Islands</option>
													<option value="BN">Brunei</option>
													<option value="BG">Bulgaria</option>
													<option value="BF">Burkina Faso</option>
													<option value="BI">Burundi</option>
													<option value="KH">Cambodia</option>
													<option value="CM">Cameroon</option>
													<option value="CA">Canada</option>
													<option value="CV">Cape Verde</option>
													<option value="KY">Cayman Islands</option>
													<option value="CF">Central African Republic</option>
													<option value="TD">Chad</option>
													<option value="CL">Chile</option>
													<option value="CN">China</option>
													<option value="CO">Colombia</option>
													<option value="KM">Comoros</option>
													<option value="CD">Congo, Democratic Republic of the</option>
													<option value="CG">Congo, Republic of</option>
													<option value="CR">Costa Rica</option>
													<option value="CI">Cote D'Ivoire (Ivory Coast)</option>
													<option value="HR">Croatia</option>
													<option value="CU">Cuba</option>
													<option value="CY">Cyprus</option>
													<option value="CZ">Czech Republic</option>
													<option value="DK">Denmark</option>
													<option value="DJ">Djibouti</option>
													<option value="DM">Dominica</option>
													<option value="DO">Dominican Republic</option>
													<option value="EC">Ecuador</option>
													<option value="EG">Egypt</option>
													<option value="SV">El Salvador</option>
													<option value="GQ">Equatorial Guinea</option>
													<option value="ER">Eritrea</option>
													<option value="EE">Estonia</option>
													<option value="ET">Ethiopia</option>
													<option value="FK">Falkland Islands</option>
													<option value="FJ">Fiji</option>
													<option value="FI">Finland</option>
													<option value="FR">France</option>
													<option value="GF">French Guiana</option>
													<option value="PF">French Polynesia</option>
													<option value="GA">Gabon</option>
													<option value="GM">Gambia</option>
													<option value="GE">Georgia</option>
													<option value="DE">Germany</option>
													<option value="GH">Ghana</option>
													<option value="GI">Gibraltar</option>
													<option value="GR">Greece</option>
													<option value="GD">Grenada</option>
													<option value="GP">Guadeloupe</option>
													<option value="GT">Guatemala</option>
													<option value="GN">Guinea</option>
													<option value="GW">Guinea-Bissau</option>
													<option value="GY">Guyana</option>
													<option value="HT">Haiti</option>
													<option value="HN">Honduras</option>
													<option value="HK">Hong Kong (China)</option>
													<option value="HU">Hungary</option>
													<option value="IS">Iceland</option>
													<option value="IN">India</option>
													<option value="ID">Indonesia</option>
													<option value="IR">Iran</option>
													<option value="IQ">Iraq</option>
													<option value="IE">Ireland</option>
													<option value="IL">Israel</option>
													<option value="IT">Italy</option>
													<option value="JM">Jamaica</option>
													<option value="JP">Japan</option>
													<option value="JO">Jordan</option>
													<option value="KZ">Kazakhstan</option>
													<option value="KE">Kenya</option>
													<option value="KP">Korea (North)</option>
													<option value="KR">Korea (South)</option>
													<option value="KW">Kuwait</option>
													<option value="KG">Kyrgyzstan</option>
													<option value="LA">Laos</option>
													<option value="LV">Latvia</option>
													<option value="LB">Lebanon</option>
													<option value="LS">Lesotho</option>
													<option value="LR">Liberia</option>
													<option value="LY">Libyan Arab Jamahiriya</option>
													<option value="LI">Liechtenstein</option>
													<option value="LT">Lithuania</option>
													<option value="LU">Luxembourg</option>
													<option value="MO">Macao</option>
													<option value="MK">Macedonia</option>
													<option value="MG">Madagascar</option>
													<option value="MW">Malawi</option>

													<option value="MV">Maldives</option>
													<option value="ML">Mali</option>
													<option value="MT">Malta</option>
													<option value="MH">Marshall Islands</option>
													<option value="MQ">Martinique</option>
													<option value="MR">Mauritania</option>
													<option value="MU">Mauritius</option>
													<option value="YT">Mayotte</option>
													<option value="MX">Mexico</option>
													<option value="FM">Micronesia, Federated States of</option>
													<option value="MD">Moldova</option>
													<option value="MC">Monaco</option>
													<option value="MN">Mongolia</option>
													<option value="MS">Montserrat</option>
													<option value="MA">Morocco</option>
													<option value="MZ">Mozambique</option>
													<option value="MM">Myanmar</option>
													<option value="NA">Namibia</option>
													<option value="NR">Nauru</option>
													<option value="NP">Nepal</option>
													<option value="NL">Netherlands</option>
													<option value="AN">Netherlands Antilles</option>
													<option value="NC">New Caledonia</option>
													<option value="NZ">New Zealand</option>
													<option value="NI">Nicaragua</option>
													<option value="NE">Niger</option>
													<option value="NG">Nigeria</option>
													<option value="NO">Norway</option>
													<option value="OM">Oman</option>
													<option value="00">Others</option>
													<option value="PK">Pakistan</option>
													<option value="PW">Palau</option>
													<option value="PS">Palestinian Territory</option>
													<option value="PA">Panama</option>
													<option value="PG">Papua New Guinea</option>
													<option value="PY">Paraguay</option>
													<option value="PE">Peru</option>
													<option value="PH">Philippines</option>
													<option value="PN">Pitcairn Islands</option>
													<option value="PL">Poland</option>
													<option value="PT">Portugal</option>
													<option value="QA">Qatar</option>
													<option value="RE">Reunion</option>
													<option value="RO">Romania</option>
													<option value="RU">Russia Federation</option>
													<option value="RW">Rwanda</option>
													<option value="SH">Saint Helena</option>
													<option value="KN">Saint Kitts and Nevis</option>
													<option value="LC">Saint Lucia</option>
													<option value="PM">Saint Pierre and Miquelon</option>
													<option value="VC">Saint Vincent and the Grenadines</option>
													<option value="WS">Samoa</option>
													<option value="SM">San Marino</option>
													<option value="ST">Sao Tome and Principe</option>
													<option value="SA">Saudi Arabia</option>
													<option value="SN">Senegal</option>
													<option value="CS">Serbia and Montenegro</option>
													<option value="SC">Seychelles</option>
													<option value="SL">Sierra Leone</option>

													<option value="SK">Slovakia</option>
													<option value="SI">Slovenia</option>
													<option value="SB">Solomon Islands</option>
													<option value="SO">Somalia</option>
													<option value="ZA">South Africa</option>
													<option value="ES">Spain</option>
													<option value="LK">Sri Lanka</option>
													<option value="SH">St. Helena</option>
													<option value="KN">St. Kitts and Nevis</option>
													<option value="LC">St. Lucia</option>
													<option value="PM">St. Pierre and Miquelon</option>
													<option value="SD">Sudan</option>
													<option value="SR">Suriname</option>
													<option value="SZ">Swaziland</option>
													<option value="SE">Sweden</option>
													<option value="CH">Switzerland</option>
													<option value="SY">Syria</option>
													<option value="TW">Taiwan</option>
													<option value="TJ">Tajikistan</option>
													<option value="TZ">Tanzania</option>
													<option value="TH">Thailand</option>
													<option value="TL">Timor-Leste</option>
													<option value="TG">Togo</option>
													<option value="TO">Tonga</option>
													<option value="TT">Trinidad and Tobago</option>
													<option value="TN">Tunisia</option>
													<option value="TR">Turkey</option>
													<option value="TM">Turkmenistan</option>
													<option value="TC">Turks and Caicos Islands</option>
													<option value="TV">Tuvalu</option>
													<option value="UG">Uganda</option>
													<option value="UA">Ukraine</option>
													<option value="AE">United Arab Emirates</option>
													<option value="GB">United Kingdom</option>
													<option value="US">United States</option>
													<option value="UY">Uruguay</option>
													<option value="UZ">Uzbekistan</option>
													<option value="VU">Vanuatu</option>
													<option value="VE">Venezuela</option>
													<option value="VN">Vietnam</option>
													<option value="WF">Wallis and Futuna Islands</option>
													<option value="EH">Western Sahara</option>
													<option value="YE">Yemen</option>
													<option value="ZR">Zaire (former)</option>
													<option value="ZM">Zambia</option>
													<option value="ZW">Zimbabwe</option>
													<option value="00">Others</option>
												</Form.Select>
											</Col>
											<Col sm={3}>
												<Form.Select disabled={checkReschedule == true ? true : false} key={`identity_type_${i}`} id={`identity_type_${i}`} className="mb-2" onChange={event => handleChangeIdentityType(event, i)} defaultValue={checkReschedule == true ? reschedulePassengerDetails[i].PassengerNric != '' ? "IC" : "PP" : (i == 0 && userData != null) ? (userData.ic == '' && userData.passport == '') ? "IC" : (userData.ic != '') ? "IC" : "PP" : "IC"}>
													<option value="IC" selected>Identity Card</option>
													<option value="PP">Passport</option>
												</Form.Select>
											</Col>
											{
												identityType[i] ?
													<>
														<Col sm={3}>
															<Form.Control disabled={checkReschedule == true ? true : false} key={`passport_${i}`} id={`passport_${i}`} type="text" placeholder="Passport No." onChange={event => parentPassengerDetailCallback(event, event.target.value, 'passport', i)} defaultValue={checkReschedule == true ? reschedulePassengerDetails[i].PassengerPassport : (i == 0 && userData != null) ? userData.passport : ""} />
														</Col>
														<Col sm={3}>
															<DatePicker
																onChange={(date) => {
																	parentPassengerDetailCallback(date, changeDateFormatToYYYYMMDD(date), 'passport_expiry_dates', i);
																	setPassportExpiryField(i, date)
																}}
																selected={ppexp[i]}
																peekNextMonth
																showMonthDropdown
																showYearDropdown
																disabled={checkReschedule == true ? true : false}
																minDate={new Date()}
																dropdownMode="select"
																placeholderText="Passport Expiry"
																dateFormat="yyyy-MM-dd"
																className='Date_Picker'
															/>
															{/* <Form.Control disabled={checkReschedule==true?true:false} key={`passport_expiry_date_${i}`} id={`passport_expiry_date_${i}`} className="ms-0" type="text" maxlength='10' placeholder="Passport Expiry" onChange={event => parentPassengerDetailCallback(event, event.target.value, 'passport_expiry_date', i)} defaultValue={checkReschedule==true? reschedulePassengerDetails[i].PassengerPassportExpiryDate:(i==0 && userData!= null)? userData.passportexpiry : ""} /> */}
															<Form.Label>(YYYY-MM-DD)</Form.Label>
														</Col>
													</>
													:
													<>
														<Col sm={6}>
															<Form.Control disabled={checkReschedule == true ? true : false} key={`ic_${i}`} id={`ic_${i}`} type="text" maxlength='14' placeholder="IC No" onChange={event => parentPassengerDetailCallback(event, event.target.value, 'ic', i)} defaultValue={checkReschedule == true ? reschedulePassengerDetails[i].PassengerNric : (i == 0 && userData != null) ? userData.ic : ""} />
														</Col>
													</>
											}
										</Row>
									</Form.Group>
								</>)}
						</section>
					</Form>
				</div>
			</div>
		</>
	);
}

export default PassengerDetails;