import React, { useEffect, useState } from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeaderSection from '../HeaderSection';
import Booking from '../Booking';
import Destination from '../Destination';
import NewsLetter from '../NewsLetter';
import Experience from '../Experience';
import { useSearchParams } from 'react-router-dom';
import { LoginSignUpModal } from '../LoginSignUpModal';

function Home(props) {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const ucf = searchParams.get("ucf");
	
	if (ucf == 'https://www.causewaylink.com.my/') {
		return <LoginSignUpModal />
	}

	return (
		<>
			<HeaderSection />
			<Booking
				subplace_data={props.subplace_data}
			/>
			<Cards
				subplace_data={props.subplace_data}
			/>
			<NewsLetter />
			<Experience />
		</>
	);
}

export default Home;
