import React, { useEffect, useState } from 'react';
import axios from 'axios';
import sha1 from 'crypto-js/sha1';
// import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const OrderSummary = () => {
	const [orderDetails, setOrderDetails] = useState(null);
	const passPhrase = "iPXG8T8emUsEk72mOU6y";
	const [loading, setLoading] = useState(true); // Add a loading state


	// const [ticketId, setTicketId] = useState(0); // Default value for ticket_id
	// const [apiKey, setKey] = useState(''); // Default value for key

	// Access URL parameters
	const searchParams = new URLSearchParams(window.location.search);
	const ticketId = searchParams.get('ticket_id');
	const apiKey = searchParams.get('key');

	console.log('ticket_id:', ticketId, 'key:', apiKey)


	// const generatePdf = () => {
	// 	const element = document.getElementById('ticket');  // ID of the element to convert to PDF
	// 	html2pdf(element);
	// };

	const generatePdf = async () => {
		const element = document.getElementById('ticket');
		const canvas = await html2canvas(element, { scale: 2 }); // Higher scale for better quality
		const imgData = canvas.toDataURL('image/png');

		const pdf = new jsPDF({
			orientation: 'portrait',
			unit: 'px',
			format: [canvas.width, canvas.height],
		});

		pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
		pdf.save('eTicket.pdf');
	};


	useEffect(() => {
		const fetchOrderDetails = async () => {
			const systemGeneratedKey = sha1(passPhrase + ticketId).toString();
			console.log(`systemGeneratedKey=${systemGeneratedKey}`);
			console.log(`apiKey=${apiKey}`);

			if (systemGeneratedKey === apiKey) {
				const url = `https://expressapi.transitos.io/api/v1/order/ticket/${apiKey}/${ticketId}`;
				console.log(`url=${url}`);

				try {
					const res = await axios.get(url);
					setOrderDetails(res.data.details);
				} catch (err) {
					console.error('Error fetching order details:', err);
				}
			} else {
				console.log('Invalid key');
			}
			setLoading(false); // Set loading to false after the call is completed
		};

		fetchOrderDetails();
	}, [apiKey, passPhrase, ticketId]);

	if (loading) {
		return <div>Loading...</div>; // Show this while loading
	}

	if (!orderDetails) {
		return <div>No order details found.</div>;
	}

	const { OrderNumber, PurchaseDate, BookingReference, DepartTripinfo, ReturnTripinfo } = orderDetails;

	return (
		<>

			<div style={{ pageBreakAfter: 'always', padding: '15px', border: '1px solid #ddd', maxWidth: '800px', margin: '0 auto' }}>
				<table style={{ width: '100%',  fontSize: '12px', color: '#555' }}>
					<tbody>
						<tr>
							<td colSpan="2">
								<div style={{ float: 'right' }}>
									<img src="https://www.causewaylink.com.my/wp-content/uploads/2020/02/logo-1.png" width="220" alt="Logo" />
								</div>
								<div style={{ float: 'left', backgroundColor: '#504371', color: '#fff', fontSize: '24px',  padding: '10px 12px' }}>
									{/* {type}  */}
									Order Summary
								</div>
							</td>
						</tr>

						<tr>
							<td colSpan="2" style={{ borderTop: '1px solid #ddd' }}>
								<p style={{ margin: '20px 0', fontWeight:'lighter' }}>
									Dear {DepartTripinfo?.SeatDetails[0]?.PassengerName},<br />
									<br />
									Thank you for ordering from <a href="https://express.causewaylink.com.my" target="_blank" rel="noopener noreferrer">Causeway Link Express</a>.<br />
									<br />
									Please be at the pick-up point and exchange your boarding pass at least <strong>30 min</strong> before departure. The terminal fee may be requested as an
									additional fee subject to the terminal terms and conditions.<br />
									<br />
									Below is your order details:
								</p>
							</td>
						</tr>

						<tr>
							<td colspan={2}>
								<hr />
							</td>
						</tr>

						<tr>
							<td>
								<strong>Boarding Code:<span style={{ color: '#f58220' }}> {OrderNumber}</span></strong>
								<br /><br />
								<strong>Purchase Date:</strong> {PurchaseDate}<br />
								<strong>Cart ID:</strong> {BookingReference}<br />
								<strong>Hotline:</strong> +607-360 2244
							</td>
							<td align="right">
								{DepartTripinfo?.BoardingCode && <img alt="" src={`https://qrcode.transitos.io/qr?text=${DepartTripinfo?.BoardingCode}`} width="130" />}
							</td>
						</tr>


						<tr>
							<td colspan={2}>

								<div id="ticket" style={{  fontSize: '12px' }}>
									{renderSummary('Depart', OrderNumber, PurchaseDate, BookingReference, DepartTripinfo)}
								</div>

							</td>
						</tr>

						{
							ReturnTripinfo && (
								<>

									<tr>
										<td colspan={2}>
											<hr />
										</td>
									</tr>

									<tr>
										<td>
											<strong>Boarding Code:<span style={{ color: '#f58220' }}> {ReturnTripinfo?.BoardingCode}</span></strong>
											<br /><br />
											<strong>Purchase Date:</strong> {PurchaseDate}<br />
											<strong>Cart ID:</strong> {BookingReference}<br />
											<strong>Hotline:</strong> +607-360 2244
										</td>
										<td align="right">
											{ReturnTripinfo?.BoardingCode && <img alt="" src={`https://qrcode.transitos.io/qr?text=${ReturnTripinfo?.BoardingCode}`} width="130" />}
										</td>
									</tr>

									<tr>
										<td colspan={2}>

											<div id="ticket" style={{ fontSize: '12px' }}>
												{ReturnTripinfo && renderSummary('Return', OrderNumber, PurchaseDate, BookingReference, ReturnTripinfo)}
											</div>

										</td>
									</tr>

								</>

							)
						}
					</tbody>
				</table>

				<TermsAndConditions />

			</div >



			{/* <button onClick={generatePdf}>Download eTicket</button> */}
		</>
	);



	function renderSummary(type, OrderNumber, PurchaseDate, BookingReference, tripInfo) {
		return (
			<>
				{renderTripDetails(tripInfo)}
			</>
		);
	}

	function renderTripDetails(tripInfo) {
		if (!tripInfo) return null;
		const { SeatDetails, FromSubPlace, ToSubPlace, DepartDate, CompanyName, CoachNumber } = tripInfo;

		return (
			<>
				<div >
					<table border="0" cellpadding="0" cellspacing="0" width="100%">
						<tbody>
							<tr>
								<td bgcolor="#504371" height="40">
									<span style={{ padding: '5px 10px', color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>Order Details</span>
								</td>
							</tr>
							<tr>
								<td >
									<table style={{  fontSize: '14px', color: '#555' }} border="1" borderColor="#dddddd" cellpadding="10" cellspacing="0" width="100%">
										<tbody>
											<tr style={{ borderBottom: '1px solid #dddddd' }}>
												<td bgcolor="#eeeeee" width="25%"><strong>Number of Pax:</strong></td>
												<td colSpan="3"><strong>{SeatDetails.length}</strong></td>
											</tr>
											<tr style={{ borderBottom: '1px solid #dddddd' }}>
												<td bgcolor="#eeeeee"><strong>Depart:</strong></td>
												<td colSpan="3">
													<b>{FromSubPlace}</b>
												</td>
											</tr>
											<tr style={{ borderBottom: '1px solid #dddddd' }}>
												<td bgcolor="#eeeeee"><strong>Arrive:</strong></td>
												<td colSpan="3">
													<b>{ToSubPlace}</b>
												</td>
											</tr>
											<tr style={{ borderBottom: '1px solid #dddddd' }}>
												<td bgcolor="#eeeeee"><strong>Departure Time:</strong></td>
												<td colSpan="3">{DepartDate && DepartDate.replace("T", " ")}</td>
											</tr>
											<tr style={{ borderBottom: '1px solid #dddddd' }}>
												<td bgcolor="#eeeeee"><strong>Company:</strong></td>
												<td width="25%">
													{"Handal Indah Sdn Bhd "}
												</td>

												{/* <td width="25%" bgcolor="#eeeeee">
													<strong>Coach Code:</strong>
												</td>
												<td>{CoachNumber}</td> */}

												<td width="25%" bgcolor="#eeeeee">
													<strong>Bus Operator:</strong>
												</td>
												<td>{
													CompanyName === "HANDAL" ? (
														<>
															<img src={`images/${CompanyName.toLowerCase()}.png`} alt={CompanyName} width={'30%'} />
															{" (Causeway Link) "}
														</>
													) : (
														<>
															<img src={`images/${CompanyName.toLowerCase()}.png`} alt={CompanyName} width={'30%'} />
															{" (" + CompanyName + ") "}
														</>
													)
												}</td>
											</tr>
											{SeatDetails.map((seat, index) => (
												<React.Fragment key={index}>
													<tr style={{ borderBottom: '1px solid #dddddd' }}>
														<td bgcolor="#eeeeee"><strong>Ticket ID:</strong></td>
														<td colSpan="3"><strong>{seat.TicketId}</strong></td>
													</tr>
													<tr style={{ borderBottom: '1px solid #dddddd' }}>
														<td bgcolor="#eeeeee"><strong>Seat Number:</strong></td>
														<td colSpan="3"><strong>{seat.SeatNumber}</strong></td>
													</tr>
													<tr style={{ borderBottom: '1px solid #dddddd' }}>
														<td bgcolor="#eeeeee"><strong>Passenger Name:</strong></td>
														<td>{seat.PassengerName}</td>
														<td bgcolor="#eeeeee"><strong>Contact:</strong></td>
														<td>{seat.PassengerContact}</td>
													</tr>
													<tr style={{ borderBottom: '1px solid #dddddd' }}>
														<td bgcolor="#eeeeee"><strong>IC:</strong></td>
														<td>{seat.PassengerNric}</td>
														<td bgcolor="#eeeeee"><strong>Passport:</strong></td>
														<td>{seat.PassengerPassport}</td>
													</tr>
													<tr style={{ borderBottom: '1px solid #dddddd' }}>
														<td bgcolor="#eeeeee"><strong>Gender:</strong></td>
														<td>{seat.PasseengerGender === "M" ? "Male" : "Female"}</td>
														<td bgcolor="#eeeeee"><strong>Passport Expiry:</strong></td>
														<td>{seat.PassengerPassportExpiryDate}</td>
													</tr>
													<tr style={{ borderBottom: '1px solid #dddddd' }}>
														<td bgcolor="#eeeeee"><strong>Date of Birth:</strong></td>
														<td>{seat.PassengerDateOfBirth?.slice(0, 10)}</td>
														<td bgcolor="#eeeeee"><strong>Nationality:</strong></td>
														<td>{seat.PasseengerNationality}</td>
													</tr>
												</React.Fragment>
											))}
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</>
		);
	}

	function TermsAndConditions() {
		return (
			<table style={{ fontSize: '11px', color: '#555', lineHeight: '18px' }} border="0" cellpadding="10" cellspacing="0" width="100%">
				<tbody>
					<tr>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>
							<p><strong>Terms and Conditions</strong></p>
							<ol style={{ paddingLeft: '15px' }}>
								<li>All Customers must present their printed E-Bus Tickets together with their
									Identification Card or Passport to the sales counter to exchange for a valid
									boarding pass.</li>
								<li>Please exchange the express bus ticket to a boarding pass at least 30
									minutes before bus departure time. Duplication of Boarding Pass will not be
									issued to replace any lost or stolen Express Bus Tickets in any
									circumstances whatsoever.</li>
								<li>It is the passenger&#39;s responsibility to make sure that they have all the
									required travel documents (eg. passport, visa, entry permit, online health
									declarations, etc) to enter the destination.</li>
								<li>Transport companies do not wait for customers to apply for visa upon arrival
									(VOA). Passengers have to apply in advance before they travel.</li>
								<li>All tickets sold are strictly non-refundable and non-changeable regardless
									of any duplicate bookings, loss of ticket, lateness or refusal of entry into
									the country of destination.</li>
								<li>Each customer is only allowed to bring on board two (2) medium-sized luggage
									during the journey, the weight of both luggage must not exceed fifteen (15)
									kilograms.</li>
								<li>Prohibited items including but not limited to drugs and flammable items are
									NOT allowed on board. Passenger will have to bear the responsibility for the
									possession of prohibited items if discovered.</li>
								<li>Please ensure the necessary travelling requirements of the destination to
									have been fulfilled before any custom clearance. Please seek assistance from
									the officer on the bus if the passenger requires any assistance.</li>
								<li>For all international travel, the bus will only wait for at most 30 minutes
									at the immigration checkpoint. Passenger is advised to check with the
									transportation driver/officer available on the bus. The bus will depart and
									board on time and it is the passenger&#39;s sole responsibility to arrange
									his/her own transport to the destination should he/she failed to get on the
									board.</li>
								<li>The Service Provider reserves the right to vary/delay/cancel the service
									without advance notice. The Service Provider will not be responsible in any
									way whatsoever for any delay in arriving to destination.</li>
								<li>Other terms and conditions are published at the <a target="_blank"
									href="http://www.causewaylink.com.my">Causeway Link website</a></li>
								<li>For any enquiries, please call 07-3602244 or email to <a
									href="mailto:express@causewaylink.com.my">express@causewaylink.com.my</a>
								</li>
								<li>You may request an e-invoice through website <a
									href="https://einvoice.handalindah.com.my/">https://einvoice.handalindah.com.my/</a>. Please note that once you request an e-invoice, you will not be able to reschedule the trip.
								</li>
							</ol>
						</td>
					</tr>
					<tr>
						<td>
							<p><strong>Exchange your boarding pass</strong></p>
							<ol style={{ paddingLeft: '15px' }}>
								<li>For customer boarding at Terminal Bersepadu Selatan (TBS), Larkin Sentral,
									Klang Sentral, Taman Ungku Tun Aminah Bus Terminal (TUTA), Terminal Shah
									Alam Section 17 and Taman Universiti Bus Terminal, Senai International
									Airport, please present your E-ticket at counter to exchange for boarding
									pass.</li>
								<li>For customer boarding at Pengerang, Bukit Bintang, LEGOLAND & Puteri
									Harbour, Batu Pahat, Air Hitam, Yong Peng, Mersing Bus Terminal, Mersing
									Jetty, please present your E-Ticket to driver upon bus boarding.</li>
							</ol>
						</td>
					</tr>
				</tbody>
			</table>
		);
	}
};

export default OrderSummary;