import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import './BookingDetails.css';
import './BookingHeader.css';
import { getAMorPMonTime } from './Function';

export const BookingDetails = ({
	// bookingDetail,
	historyData,
	// depart_info,
	// return_info,
	// depart_date,
	// return_date,
	// data_depart,
	// data_destination,
	// data_passenger,
	addon,
	view
}) => {
	console.log(view)
	console.log(historyData)
	// console.log(bookingDetail)
	console.log(addon)

	let subplace_data = sessionStorage.getItem("subplace_data");
	if (sessionStorage.getItem("subplace_data") != null) {
		subplace_data = JSON.parse(subplace_data);
	}
	// console.log(subplace_data)
	var old_guid = JSON.parse(sessionStorage.getItem("oldcartguid"));
	var reschedule_data = JSON.parse(sessionStorage.getItem("reschedule_data"));

	// console.log(old_guid)

	function saveCurrentTripInformation(isReturnTrip) {
		sessionStorage.removeItem("Origin_data");
		sessionStorage.removeItem("Destination_data");
		sessionStorage.removeItem("returnData");
		sessionStorage.removeItem("departData");
		sessionStorage.removeItem("busFare");
		localStorage.removeItem("loyalty");
		sessionStorage.removeItem("addOn");
		sessionStorage.removeItem("selectedDepartSeat");
		sessionStorage.removeItem("selectedReturnSeat");
		localStorage.removeItem("selected_addon_data")
		localStorage.removeItem("addOnSession");
		sessionStorage.removeItem("addon_data");
		sessionStorage.removeItem("depart_TripKey");
		sessionStorage.removeItem("return_TripKey");

		var guid = JSON.parse(sessionStorage.getItem("history_guid"));

		var reschedule_passenger = isReturnTrip ? historyData.returntripinfo.seatdetails.length : historyData.departtripinfo.seatdetails.length;
		var reschedule_passengerDetails = isReturnTrip ? historyData.returntripinfo.seatdetails : historyData.departtripinfo.seatdetails;
		// console.log(reschedule_passengerDetails)
		var reschedule_departDate = isReturnTrip ? historyData.returntripinfo.departdate.split(' ')[0] : historyData.departtripinfo.departdate.split(' ')[0];
		var reschedule_returnDate = isReturnTrip ? historyData.returntripinfo.departdate.split(' ')[0] : (historyData.returntripinfo != null ? historyData.returntripinfo.departdate.split(' ')[0] : null);


		for (let i = 0; i < subplace_data.length; i++) {
			for (let j = 0; j < subplace_data[i].subplaces.length; j++) {
				if (subplace_data[i].subplaces[j].subplaceid === parseInt(
					isReturnTrip ? historyData.returntripinfo.fromsubplaceid :
						historyData.departtripinfo.fromsubplaceid
				)) {
					sessionStorage.setItem("Origin_data", JSON.stringify(
						{
							"placeid": subplace_data[i].placeid,
							"placename": subplace_data[i].placename,
							"placeuniqueshortname": subplace_data[i].placeuniqueshortname,
							"subplaceid": subplace_data[i].subplaces[j].subplaceid,
							"subplacename": subplace_data[i].subplaces[j].subplacename,
							"subplacedisplayname": subplace_data[i].subplaces[j].subplacedisplayname,
							"subplaceuniqueshortname": subplace_data[i].subplaces[j].subplaceuniqueshortname,

							"oldcartguid": guid,
							"departdate": reschedule_departDate,
							"returndate": reschedule_returnDate,
							"passenger": reschedule_passenger,
							"addondata": addon,
							"reschedule": true,
							"passengerdetails": reschedule_passengerDetails
						}
					)
					)
				}
				if (subplace_data[i].subplaces[j].subplaceid === parseInt(
					// historyData.return_sub_place_id
					isReturnTrip ? historyData.returntripinfo.tosubplaceid :
						historyData.departtripinfo.tosubplaceid
				)) {
					sessionStorage.setItem("Destination_data", JSON.stringify(
						{
							"placeid": subplace_data[i].placeid,
							"placename": subplace_data[i].placename,
							"placeuniqueshortname": subplace_data[i].placeuniqueshortname,
							"subplaceid": subplace_data[i].subplaces[j].subplaceid,
							"subplacename": subplace_data[i].subplaces[j].subplacename,
							"subplacedisplayname": subplace_data[i].subplaces[j].subplacedisplayname,
							"subplaceuniqueshortname": subplace_data[i].subplaces[j].subplaceuniqueshortname
						}
					)
					)
				}
			}
		}
		// console.log(JSON.parse(sessionStorage.getItem("Origin_data")))
		// console.log(JSON.parse(sessionStorage.getItem("Destination_data")))
		window.location.href = "/pickDepart"
	}

	function splitDateTime(dateTime, time = false) {
		var dateTimeArr = dateTime.split(" ");
		if (time == false) {
			var date = dateTimeArr[0];
			return date;
		}
		else {
			var time = dateTimeArr[1];
			time = getAMorPMonTime(time);
			return time;
		}
	}
	// console.log(bookingDetail)

	// if (bookingDetail != null) {

	// 	// function getDepartAndDestinationPlaceName(DepartOrDestination = 'Depart', Return = false) {
	// 	//     var placeNameArr = bookingDetail.details.split(" - ");
	// 	//     if (DepartOrDestination == 'Depart') {
	// 	//         return placeNameArr[0];
	// 	//     }
	// 	//     else return placeNameArr[1];
	// 	// }

	// 	// function getDateOrTime(DateOrTime = 'Date', Return = false) {
	// 	//     var dateTimeArr = bookingDetail.depart_date.split(" ");
	// 	//     if (Return == true) {
	// 	//         dateTimeArr = bookingDetail.return_date.split(" ");
	// 	//     }
	// 	//     if (DateOrTime == 'Date') {
	// 	//         return dateTimeArr[0];
	// 	//     }
	// 	//     else return dateTimeArr[1] + dateTimeArr[2];
	// 	// }
	// 	// // console.log(bookingDetail.depart_date)
	// 	if (bookingDetail.ordersummary != null) {
	// 		//status 200, normal display
	// 		return (
	// 			<>
	// 				<Container>
	// 					<div className='upcoming-booking-container'>
	// 						<div className='table-container'>
	// 							<Row className="clear-margin">
	// 								<Col className='background-blue'>
	// 									<h1>BOOKING DETAILS</h1>
	// 								</Col>
	// 							</Row>

	// 							<Row className="clear-margin p-3">
	// 								<Col sm={bookingDetail.ordersummary.returntripinfo != null ? 6 : 12}>
	// 									<h2>DEPART</h2>
	// 									<table width={'100%'} className='m-auto'>
	// 										<tr>
	// 											<td width={'33%'}><h6>{bookingDetail.ordersummary.departtripinfo.fromplace}</h6></td>
	// 											<td className='center' rowSpan={2}><img src='images/ic_oneway.png' /></td>
	// 											<td width={'33%'}><h6>{bookingDetail.ordersummary.departtripinfo.toplace}</h6></td>
	// 										</tr>
	// 										<tr>
	// 											<td><h5>{bookingDetail.ordersummary.departtripinfo.fromsubplace}</h5></td>
	// 											<td><h5>{bookingDetail.ordersummary.departtripinfo.tosubplace}</h5></td>
	// 										</tr>
	// 										{/* <tr>
	// 											<td><h6>Address: {bookingDetail.ordersummary.departtripinfo.pickupaddress}</h6></td>
	// 											<td></td>
	// 											<td><h6>Address: {bookingDetail.ordersummary.departtripinfo.dropoffaddress}</h6></td>
	// 										</tr> */}
	// 									</table>
	// 									<Row className="mt-5 details_container mr-1">
	// 										<Col xs={6} sm={6}>
	// 											<h6>Departure</h6>
	// 											<h5>{splitDateTime(bookingDetail.ordersummary.departtripinfo.departdate, true)}</h5>
	// 										</Col>
	// 										<Col xs={6} sm={6}>
	// 											<h6>Date</h6>
	// 											<h5>{splitDateTime(bookingDetail.ordersummary.departtripinfo.departdate)}</h5>
	// 										</Col>
	// 										<Col xs={6} sm={6} className="mt-4">
	// 											<h6>Duration</h6>
	// 											<h5>{bookingDetail.orderstatus.depart_duration}</h5>
	// 										</Col>

	// 										{/* {!view ? (
	//                                             <Col xs={6} sm={8} className="mt-4">
	//                                                 <LinkButton
	//                                                     className=""
	//                                                     buttonStyle="btn--outline"
	//                                                     buttonSize="btn--change--trip"
	//                                                     linkTo="/pickDepart"
	//                                                     children="Change Trip"
	//                                                 // params={
	//                                                 //     [
	//                                                 //         `depart=${data_depart}`,
	//                                                 //         `destination=${data_destination}`,
	//                                                 //         `depart_date=${depart_date}`,
	//                                                 //         `return_date=${return_date}`,
	//                                                 //         `passenger=${data_passenger}`
	//                                                 //     ]
	//                                                 // }
	//                                                 >
	//                                                 </LinkButton>
	//                                             </Col>
	//                                         ) : ("")} */}
	// 									</Row>
	// 								</Col>

	// 								{bookingDetail.ordersummary.returntripinfo != null ?
	// 									<Col sm={6} className='booking-border-left'>
	// 										<div className='ml-1'>
	// 											<h2>RETURN</h2>
	// 											<table width={'100%'} className='m-auto'>
	// 												<tr>
	// 													<td width={'33%'}><h6>{bookingDetail.ordersummary.returntripinfo.fromplace}</h6></td>
	// 													<td className='center' rowSpan={2}><img src='images/ic_oneway.png' /></td>
	// 													<td width={'33%'}><h6>{bookingDetail.ordersummary.returntripinfo.toplace}</h6></td>
	// 												</tr>
	// 												<tr>
	// 													<td><h5>{bookingDetail.ordersummary.returntripinfo.fromsubplace}</h5></td>
	// 													<td><h5>{bookingDetail.ordersummary.returntripinfo.tosubplace}</h5></td>
	// 												</tr>
	// 												{/* <tr>
	// 													<td><h6>Address: {bookingDetail.ordersummary.returntripinfo.pickupaddress}</h6></td>
	// 													<td></td>
	// 													<td><h6>Address: {bookingDetail.ordersummary.returntripinfo.dropoffaddress}</h6></td>
	// 												</tr> */}
	// 											</table>
	// 										</div>
	// 										<Row className="mt-5 details_container ml-1">
	// 											<Col xs={6} sm={6}>
	// 												<h6>Departure</h6>
	// 												<h5>{splitDateTime(bookingDetail.ordersummary.returntripinfo.departdate, true)}</h5>
	// 											</Col>
	// 											<Col xs={6} sm={6}>
	// 												<h6>Date</h6>
	// 												<h5>{splitDateTime(bookingDetail.ordersummary.returntripinfo.departdate)}</h5>
	// 											</Col>
	// 											<Col xs={6} sm={6} className="mt-4">
	// 												<h6>Duration</h6>
	// 												<h5>{bookingDetail.orderstatus.return_duration}</h5>
	// 											</Col>

	// 											{/* {!view ? (
	//                                                 <Col xs={6} sm={6} className="mt-4">
	//                                                     <LinkButton
	//                                                         className=""
	//                                                         buttonStyle="btn--outline"
	//                                                         buttonSize="btn--change--trip"
	//                                                         linkTo="/pickReturn"
	//                                                         children="Change Trip"
	//                                                         // params={
	//                                                         //     [
	//                                                         //         `depart=${historyData.DepartTripinfo.FromSubPlace}`,
	//                                                         //         `destination=${data_destination}`,
	//                                                         //         `depart_date=${depart_date}`,
	//                                                         //         `return_date=${return_date}`,
	//                                                         //         `passenger=${data_passenger}`,
	//                                                         //         `depart_info=${depart_info}`
	//                                                         //     ]
	//                                                         // }
	//                                                     >
	//                                                     </LinkButton>
	//                                                 </Col>
	//                                             ) : ("")} */}
	// 										</Row>
	// 									</Col>
	// 									: ""}
	// 							</Row>
	// 						</div>
	// 					</div>
	// 				</Container>
	// 			</>
	// 		);
	// 	}
	// 	else {
	// 		//status 206 (pending confirmation)
	// 		return (
	// 			<>
	// 				<Container>
	// 					<div className='upcoming-booking-container'>
	// 						<div className='table-container'>
	// 							<Row className="clear-margin">
	// 								<Col className='background-blue'>
	// 									<h1>BOOKING DETAILS</h1>
	// 								</Col>
	// 							</Row>
	// 							<Row className="clear-margin p-3">
	// 								<Col sm={bookingDetail.orderstatus.return_date != "" ? 6 : 12}>
	// 									<h2>DEPART</h2>
	// 									<table width={'100%'} className='m-auto'>
	// 										<tr>
	// 											<td width={'33%'}><h6>{bookingDetail.orderstatus.depart_place_name}</h6></td>
	// 											<td className='center' rowSpan={2}><img src='images/ic_oneway.png' /></td>
	// 											<td width={'33%'}><h6>{bookingDetail.orderstatus.return_place_name}</h6></td>
	// 										</tr>
	// 										<tr>
	// 											<td><h5>{bookingDetail.orderstatus.depart_sub_place_name}</h5></td>
	// 											<td><h5>{bookingDetail.orderstatus.return_sub_place_name}</h5></td>
	// 										</tr>
	// 									</table>
	// 									<Row className="mt-5 details_container mr-1">
	// 										<Col xs={6} sm={6}>
	// 											<h6>Departure</h6>
	// 											<h5>{bookingDetail.orderstatus.depart_date}</h5>
	// 										</Col>
	// 										<Col xs={6} sm={6}>
	// 											<h6>Date</h6>
	// 											<h5>{bookingDetail.orderstatus.depart_time}</h5>
	// 										</Col>
	// 										<Col xs={6} sm={6} className="mt-4">
	// 											<h6>Duration</h6>
	// 											<h5>{bookingDetail.orderstatus.depart_duration}</h5>
	// 										</Col>

	// 										{/* {!view ? (
	//                                             <Col xs={6} sm={8} className="mt-4">
	//                                                 <LinkButton
	//                                                     className=""
	//                                                     buttonStyle="btn--outline"
	//                                                     buttonSize="btn--change--trip"
	//                                                     linkTo="/pickDepart"
	//                                                     children="Change Trip"
	//                                                 // params={
	//                                                 //     [
	//                                                 //         `depart=${data_depart}`,
	//                                                 //         `destination=${data_destination}`,
	//                                                 //         `depart_date=${depart_date}`,
	//                                                 //         `return_date=${return_date}`,
	//                                                 //         `passenger=${data_passenger}`
	//                                                 //     ]
	//                                                 // }
	//                                                 >
	//                                                 </LinkButton>
	//                                             </Col>
	//                                         ) : ("")} */}
	// 									</Row>
	// 								</Col>

	// 								{bookingDetail.orderstatus.return_date != "" ?
	// 									<Col sm={6} className='booking-border-left'>
	// 										<div className='ml-1'>
	// 											<h2>RETURN</h2>
	// 											<table width={'100%'} className='m-auto'>
	// 												<tr>
	// 													<td width={'33%'}><h6>{bookingDetail.orderstatus.return_place_name}</h6></td>
	// 													<td className='center' rowSpan={2}><img src='images/ic_oneway.png' /></td>
	// 													<td width={'33%'}><h6>{bookingDetail.orderstatus.depart_place_name}</h6></td>
	// 												</tr>
	// 												<tr>
	// 													<td><h5>{bookingDetail.orderstatus.return_sub_place_name}</h5></td>
	// 													<td><h5>{bookingDetail.orderstatus.depart_sub_place_name}</h5></td>
	// 												</tr>
	// 											</table>
	// 										</div>
	// 										<Row className="mt-5 details_container ml-1">
	// 											<Col xs={6} sm={6}>
	// 												<h6>Departure</h6>
	// 												<h5>{bookingDetail.orderstatus.return_date}</h5>
	// 											</Col>
	// 											<Col xs={6} sm={6}>
	// 												<h6>Date</h6>
	// 												<h5>{bookingDetail.orderstatus.return_time}</h5>
	// 											</Col>
	// 											<Col xs={6} sm={6} className="mt-4">
	// 												<h6>Duration</h6>
	// 												<h5>{bookingDetail.orderstatus.return_duration}</h5>
	// 											</Col>
	// 										</Row>
	// 									</Col>
	// 									: ""}
	// 							</Row>
	// 						</div>
	// 					</div>
	// 				</Container>
	// 			</>
	// 		);
	// 	}
	// }
	// else 
	if (historyData != null) {

		return (
			<>
				<Container>
					<div className='upcoming-booking-container'>
						<div className='table-container'>
							<Row className="clear-margin">
								<Col className='background-blue'>
									<h1>BOOKING DETAILS</h1>
								</Col>
							</Row>

							<Row className="clear-margin p-3">
								<Col sm={historyData.returntripinfo != null ? 6 : 12}>

									<Row className="mb-2">
										<Col sm={12}>
											<div className='booking-status'>
												{historyData.departtripinfo.boardingcode}
											</div>
										</Col>
									</Row>



									<h2>DEPART</h2>
									<table width={'100%'} className='m-auto'>
										<tr>
											<td width={'40%'}><h6>{historyData.departtripinfo.fromplace}</h6></td>
											<td className='center' rowSpan={2}><img src='images/ic_oneway.png' /></td>
											<td width={'40%'}><h6>{historyData.departtripinfo.toplace}</h6></td>
										</tr>
										<tr>
											<td><h5>{historyData.departtripinfo.fromsubplace}</h5></td>
											<td><h5>{historyData.departtripinfo.tosubplace}</h5></td>
										</tr>
										{/* <tr>
											<td><h6>Address: {historyData.departtripinfo.pickupaddress}</h6></td>
											<td></td>
											<td><h6>Address: {historyData.departtripinfo.dropoffaddress}</h6></td>
										</tr> */}
									</table>
									<Row className="mt-5 details_container mr-1">
										<Col xs={6} sm={6}>
											<h6>Departure</h6>
											<h5>{splitDateTime(historyData.departtripinfo.departdate, true)}</h5>
										</Col>
										<Col xs={6} sm={6}>
											<h6>Date</h6>
											<h5>{splitDateTime(historyData.departtripinfo.departdate)}</h5>
										</Col>
										{/* <Col xs={6} sm={6} className="mt-4">
                                        <h6>Duration</h6>
                                        <h5></h5>
                                    </Col> */}

										{!view ? (

											old_guid == "" ?

												<Col xs={6} sm={6} className="mt-4">

													{/* <button
														className="change_trip"
														children="Change Trip"

														//immediately invoking the saveCurrentTripInformation function with the argument false and assigning its return value
														// onClick={saveCurrentTripInformation(false)}
														onClick={() => saveCurrentTripInformation(false)}

													//step 1: Homepage getList api save to localstorage
													//step 2: this onClick function get localstorage, loop and save the origin and destination data
													//step 3: add passenger information and date information inside
													//step 4: redirect to pick depart page, get data from new data set
													//step 5: param set reschedule = true only use new data set
													//step 6: if using new data set, disable edit search function, and maybe addon
													//step 7: payment part, call API to get reschedule fee by passing oldcartguid and new payment amount when proceed to checkout
													//step 8: done
													// params={
													//     [
													//         `depart=${data_depart}`,
													//         `destination=${data_destination}`,
													//         `depart_date=${depart_date}`,
													//         `return_date=${return_date}`,
													//         `passenger=${data_passenger}`
													//     ]
													// }
													>
													</button> */}
												</Col>
												: ""

										) : ("")}
									</Row>


									{/* <Row className="mt-1 details_container mr-1">
										<Col xs={6} sm={6}>
											<h6>Currency</h6>
											<h5>{historyData.currency}</h5>
										</Col>
										<Col xs={6} sm={6}>
											<h6>Total Amount</h6>
											<h5>{historyData.totalamount}</h5>
										</Col>
									</Row> */}

								</Col>

								{historyData.returntripinfo != null ?
									<Col sm={6} className='booking-border-left'>

										<Row className="mb-2">
											<Col sm={12}>
												<div className='booking-status px-2'>
													{historyData.returntripinfo.boardingcode}
												</div>
											</Col>
										</Row>


										<div className='ml-1'>
											<h2>RETURN</h2>
											<table width={'100%'} className='m-auto'>
												<tr>
													<td width={'40%'}><h6>{historyData.returntripinfo.fromplace}</h6></td>
													<td className='center' rowSpan={2}><img src='images/ic_oneway.png' /></td>
													<td width={'40%'}><h6>{historyData.returntripinfo.toplace}</h6></td>
												</tr>
												<tr>
													<td><h5>{historyData.returntripinfo.fromsubplace}</h5></td>
													<td><h5>{historyData.returntripinfo.tosubplace}</h5></td>
												</tr>
												{/* <tr>
													<td><h6>Address: {historyData.returntripinfo.pickupaddress}</h6></td>
													<td></td>
													<td><h6>Address: {historyData.returntripinfo.dropoffaddress}</h6></td>
												</tr> */}
											</table>
										</div>
										<Row className="mt-5 details_container ml-1">
											<Col xs={6} sm={6}>
												<h6>Departure</h6>
												<h5>{splitDateTime(historyData.returntripinfo.departdate, true)}</h5>
											</Col>
											<Col xs={6} sm={6}>
												<h6>Date</h6>
												<h5>{splitDateTime(historyData.returntripinfo.departdate)}</h5>
											</Col>
											{/* <Col xs={6} sm={6} className="mt-4">
                                            <h6>Duration</h6>
                                            <h5>{}</h5>
                                        </Col> */}

											{!view ? (
												old_guid === "" ?
													<Col xs={6} sm={6} className="mt-4">
														{/* <button
															className="change_trip"
															children="Change Trip"
															// onClick={saveCurrentTripInformation(true)}
															onClick={() => saveCurrentTripInformation(true)}
														>
														</button> */}
													</Col>
													: ""
											) : ("")}
										</Row>
									</Col>

									: ""}
							</Row>

							<Row className="clear-margin">
								<Col className='bg-yellow'>
								
									<h1 className="text-right">Total Amount: {historyData.currency} {historyData.totalamount}</h1>
								</Col>
							</Row>

						</div>
					</div>
				</Container>
			</>
		)
	}
}


