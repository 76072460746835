import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import { useSearchParams } from 'react-router-dom';
import { getSubPlaceShortName } from './Function';
import './Proceed_Departure.css';
import { Seat } from './Seat';

export const Proceed_Departure = ({

}) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const data_depart = searchParams.get("depart");
	const data_destination = searchParams.get("destination");
	const data_depart_date = searchParams.get("depart_date");
	const data_return_date = searchParams.get("return_date");
	let data_passenger = searchParams.get("passenger");
	const origin_data = JSON.parse(sessionStorage.getItem("Origin_data"));
	const destination_data = JSON.parse(sessionStorage.getItem("Destination_data"));

	if (data_passenger > 8) {
		data_passenger = 8;
	}
	// const {departData, setDepartData} = useContext(DepartDataContext);
	const departData = JSON.parse(sessionStorage.getItem("departData"));
	if (departData != null) {
		sessionStorage.setItem("depart_TripKey", JSON.stringify(departData.TripKey))
	}

	return (
		<Container>
			<div className='proceed_departure_container'>
				<div className='proceed_table_container'>
					<Row className="clear-margin">
						<Col className='background-blue'>
							<Row>
								<Col xs={3} sm={3}>
									<h1>Departure</h1>
								</Col>
								<Col xs={9} sm={9}>
									<h3>
										{(origin_data.subplacedisplayname.length > 12
											? `${origin_data.subplacedisplayname.substring(0, 12)}...`
											: origin_data.subplacedisplayname
										)}
										<img src="images/ic_to.png" alt="to" />

										{(destination_data.subplacedisplayname.length > 12
											? `${destination_data.subplacedisplayname.substring(0, 12)}...`
											: destination_data.subplacedisplayname
										)}
									</h3>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<div>
							<Seat
								currency='MYR'
								proceed='depart'
								trip_key={departData.TripKey}
								passenger={data_passenger != null ? data_passenger : origin_data.Passenger}
							// passenger={data_passenger!=null?data_passenger:1}
							/>
						</div>
					</Row>
					<Row>
						<Col xs={6} className='seatText_taken'>
							<img className='p-1 ms-auto me-auto' src="images/ic_taken.png" alt="seating" />
							<p>Taken</p>
						</Col>
						<Col xs={6} className='seatText_available'>
							<img className='p-1 ms-auto me-auto' src="images/ic_available.png" alt="seating" />
							<p>Available</p>
						</Col>
					</Row>
				</div>
			</div>
		</Container>
	);
}

