import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Outlet } from 'react-router-dom'; // Import Outlet from react-router-dom


const MainLayout = () => {
	return (
		<>
			<Navbar />
			<main>
				<Outlet /> {/* This will render the content of the current route */}
			</main>
			<Footer />
		</>
	);
};

export default MainLayout;
