import React, { useContext, useEffect, useState } from 'react';
import { SelectSeatDepartContext, SelectSeatReturnContext, LoyaltyDiscountContext, LoyaltyIDContext, VoucherCodeContext, VoucherDiscountContext } from '../UserContext';
import { getBusSeatColumn, getSeatCode, getToken, tokenIsValid } from './Function';
import { LoadingScreenModal } from './LoadingScreenModal';
import './Seat.css';


export const Seat = ({
	currency = 'MYR',
	proceed,
	trip_key,
	passenger = 1
}) => {
	const SERVER_URL = process.env.REACT_APP_SERVER_URL;
	const [data, setData] = useState(null);
	const [dataLoad, setDataLoad] = useState(false);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [click, setClick] = useState(false);
	const { selectedDepartSeat, setDepartSeat } = useContext(SelectSeatDepartContext);
	const { selectedReturnSeat, setReturnSeat } = useContext(SelectSeatReturnContext);
	const { LoyaltyID, setLoyaltyID } = useContext(LoyaltyIDContext);
	const { TotalLoyalty, setLoyalty } = useContext(LoyaltyDiscountContext);
	const { VoucherCode, setVoucherCode } = useContext(VoucherCodeContext);
	const { VoucherDiscount, setVoucherDiscount } = useContext(VoucherDiscountContext);

	function checkSelected(allSelectedSeats, newSelect) {
		for (let i = 0; i < allSelectedSeats.length; i++) {
			if (allSelectedSeats[i].DisplayCode === (newSelect)) {
				return i;
			}
		}
		return false;
	}

	useEffect(() => {
		setDepartSeat([]);
		setReturnSeat([]);
		setLoyaltyID(0);
		setLoyalty(0);
		setVoucherCode(null);
		setVoucherDiscount(0);
	}, [])
	// console.log(selectedDepartSeat)

	function selectedSeat(newSelect, SeatId, SeatCode, SeatNumber, IsAvailable, SeatDeck, ElementId) {

		if (SeatId != 'none' && IsAvailable === true) {
			if (proceed === 'depart') {
				const checkDepartResult = checkSelected(selectedDepartSeat, newSelect);
				// console.log(checkDepartResult)
				if (checkDepartResult === false && selectedDepartSeat.length < passenger) {
					setDepartSeat(selectedDepartSeat => [...selectedDepartSeat,
					(
						{
							"DisplayCode": newSelect,
							"SeatId": SeatId,
							"SeatCode": SeatCode,
							"SeatNumber": SeatNumber,
							"SeatDeck": SeatDeck
						}
					)
					]);
					var element = document.getElementById(ElementId);
					element.classList.add('selected_seat_colour');
				}
				else if (checkDepartResult === false && selectedDepartSeat.length >= passenger) {
					alert(`The total number of passengers is ${passenger}. You have selected too many seats`)
				}
				else {
					var index = checkDepartResult;
					if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
						selectedDepartSeat.splice(index, 1);
						setDepartSeat(selectedDepartSeat => [...selectedDepartSeat]);
						var element = document.getElementById(ElementId);
						element.classList.remove('selected_seat_colour');
					}
				}
				sessionStorage.setItem("selectedDepartSeat", JSON.stringify(selectedDepartSeat));
			}
			else {
				const checkReturnResult = checkSelected(selectedReturnSeat, newSelect);
				// console.log(checkReturnResult)
				if (checkReturnResult === false && selectedReturnSeat.length < passenger) {
					setReturnSeat(selectedReturnSeat => [...selectedReturnSeat,
					(
						{
							"DisplayCode": newSelect,
							"SeatId": SeatId,
							"SeatCode": SeatCode,
							"SeatNumber": SeatNumber,
							"SeatDeck": SeatDeck
						}
					)
					]);
					var element = document.getElementById(ElementId);
					element.classList.add('selected_seat_colour');
				}
				else if (checkReturnResult === false && selectedReturnSeat.length >= passenger) {
					alert(`The total number of passengers is ${passenger}. You have selected too many seats`)
				}
				else {
					var index = checkReturnResult;
					// console.log(index);
					if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
						selectedReturnSeat.splice(index, 1);
						setReturnSeat(selectedReturnSeat => [...selectedReturnSeat]);
						var element = document.getElementById(ElementId);
						element.classList.remove('selected_seat_colour');
					}
				}
			}
		}
	}

	//yoyo =  y = row, x = column
	function corridor(X, Y) {
		if (X === 2 && Y === '' || X === 3 && Y === '') {
			return 'corridor';
		}
	}
	// let url = "https://expressapi.transitos.io/api/v1/eb/get/seats/MYR/MY-int-51559358-abe6a90d-8f8b-47e3-abc6-5ef4f88771f8-5877"
	let url = `${SERVER_URL}/me/get/seats/${currency}/${trip_key}`;

	useEffect(() => {
		const getData = async () => {
			try {
				const response = await fetch(
					url,
					{
						method: "POST",
						headers: {
							'Authorization': getToken()
						}
					}
				);
				if (!response.ok) {
					throw new Error(
						`This is an HTTP error: The status is ${response.status}`
					);
				}
				let actualData = await response.json();
				setData(actualData);
				setError(null);
				setDataLoad(true);
				// console.log(actualData)
			} catch (err) {
				setError(err.message);
				setData(null);
			} finally {
				setLoading(false);
			}
		}
		tokenIsValid()
		getData()
	}, [getToken()])

	// console.log(data);
	let array_lower_seats = null;
	let array_upper_seats = null;


	if (dataLoad) {
		if (
			//if upper deck total column is zero
			data.busseatplan.upperdecktotalcol == 0
			//	data.busseatplan.decks.length == 1
		) {

			if (data.busseatplan.decks.length > 0) {

				array_lower_seats = (data.busseatplan.decks[0].seats);
				array_lower_seats.sort((a, b) => a.Y - b.Y);

				// const allSeats = array_lower_seats.length;
				// const column = getBusSeatColumn(allSeats);
				const column = data.busseatplan.lowerdecktotalcol;

				// const row = (data.busseatplan.decks[0].lower_total_row);
				let chunk_size = column;

				// if (allSeats > 40 && array_lower_seats[2].X != 3) {
				//   chunk_size = 4;
				// }



				function chunkArray(array, chunkSize) {
					const result = [];
					for (let i = 0; i < array.length; i += chunkSize) {
						const chunk = array.slice(i, i + chunkSize);
						result.push(chunk);
					}
					return result;
				}

				// function twoDeckSliceIntoChunks(arr, chunkSize) {
				// 	const res = [];
				// 	let lastRow = 0;

				// 	for (let i = 0; i < arr.length; i += chunkSize) {
				// 		if ((i + chunkSize + chunkSize) > arr.length) {
				// 			lastRow = 1;
				// 		}
				// 		const chunk = arr.slice(i, i + chunkSize + lastRow);
				// 		const corridor = { 'IsAvailable': 'corridor', 'SeatId': 'none', 'X': 2, Y: "" }
				// 		const corridor2 = { 'IsAvailable': 'corridor', 'SeatId': 'none', 'X': 3, Y: "" }
				// 		// console.log(chunk)
				// 		// console.log(arr)
				// 		// console.log(chunk.splice(1,0))
				// 		// console.log(chunk[1].X)
				// 		// console.log(chunk[2])
				// 		if (typeof (chunk[1]) !== 'undefined' && chunk[1].X != 2) {
				// 			chunk.splice(1, 0, corridor);
				// 			res.push(chunk);
				// 		}
				// 		else if (typeof (chunk[2]) !== 'undefined' && chunk[2].X != 3) {
				// 			chunk.splice(2, 0, corridor2);
				// 			res.push(chunk);
				// 		}
				// 		else {
				// 			res.push(chunk);
				// 			i += chunkSize;
				// 		}
				// 	}
				// 	return res;
				// }
				array_lower_seats = chunkArray(array_lower_seats, chunk_size);
			}
		}

		else if (data.busseatplan.decks.length > 1) {

			var lower_deck_total_column = data.busseatplan.lowerdecktotalcol;
			var upper_deck_total_column = data.busseatplan.upperdecktotalcol;

			data.busseatplan.decks.forEach(deck => {
				if (deck.deckname.toLowerCase() === 'upper') {
					array_upper_seats = deck.seats;
				} else if (deck.deckname.toLowerCase() === 'lower') {
					array_lower_seats = deck.seats;
				}
			});

			// if (data.busseatplan.decks[0].deckname.includes('upper')) {
			//   array_lower_seats = (data.busseatplan.decks[0].seats);
			//   array_upper_seats = (data.busseatplan.decks[1].seats);
			// }
			// else if (data.busseatplan.decks[0].deckname.includes('lower')) {
			//   array_lower_seats = (data.busseatplan.decks[1].seats);
			//   array_upper_seats = (data.busseatplan.decks[0].seats);
			// }

			array_lower_seats.sort((a, b) => a.Y - b.Y);
			array_upper_seats.sort((a, b) => a.Y - b.Y);

			// const allSeats = array_lower_seats.length;
			// const column = getBusSeatColumn(allSeats);
			// const row = 4;


			function chunkArray(array, chunkSize) {
				const result = [];
				for (let i = 0; i < array.length; i += chunkSize) {
					const chunk = array.slice(i, i + chunkSize);
					result.push(chunk);
				}
				return result;
			}

			// console.log(array_upper_seats)
			// function twoDeckSliceIntoChunks(arr, chunkSize) {
			// 	const res = [];
			// 	let lastRow = 0;

			// 	for (let i = 0; i < arr.length; i += chunkSize) {
			// 		if ((i + chunkSize + chunkSize) > arr.length) {
			// 			lastRow = 1;
			// 		}
			// 		// console.log(i)
			// 		const chunk = arr.slice(i, i + chunkSize + lastRow);
			// 		const corridor = { 'IsAvailable': 'corridor', 'SeatId': 'none', 'X': 2, Y: "" }

			// 		// console.log(chunk)
			// 		// console.log(arr)
			// 		// console.log(chunk.splice(1,0))
			// 		// // console.log(chunk[1].X)

			// 		if (typeof (chunk[1]) !== 'undefined' && chunk[1].X != 2) {
			// 			chunk.splice(1, 0, corridor);
			// 			res.push(chunk);
			// 		}
			// 		else {
			// 			res.push(chunk);
			// 			if (chunkSize != 4) {
			// 				i += chunkSize;
			// 			}
			// 		}
			// 	}
			// 	return res;
			// }

			array_lower_seats = chunkArray(array_lower_seats, lower_deck_total_column);
			array_upper_seats = chunkArray(array_upper_seats, upper_deck_total_column);
		}
		else {
			window.location.href = '/'
		}
	}


	const Selected = (IsAvailable, IsAisle) => {
		if (IsAisle === true) {
			return 'corridor';
		} else {
			if (IsAvailable === true) {
				return 'available';
			}
		}
		return 'not_available';
	}


	// this is for single deck
	if (dataLoad && array_upper_seats == null) {
		return (
			<>
				{loading && <div className='center'>
					<LoadingScreenModal />
				</div>}
				{error && (
					<div>{`There is a problem fetching the post data - ${error}`}</div>
				)}


				{array_lower_seats && array_lower_seats.length > 0 ? (
					<div className='flex_display'>
						<div className='seat_container'>
							<img className='ms-5' src="images/img_steering.png" alt="img_steering" />
							<hr></hr>


							<table className='seat'>
								{array_lower_seats.map(({ }, index) => (
									<tr>
										{array_lower_seats[index].map((item_seat) => (
											<td
											// className={corridor(item_seat.x, item_seat.y)}
											>
												<a
													href="javascript:void(0)"
													key={`${trip_key}-${item_seat.seatid}`}

													value={item_seat.seatcode
														//  getSeatCode(item_seat.seatcode, item_seat.x, item_seat.y, array_lower_seats[0].length)
													}
													onClick={() => selectedSeat(
														//getSeatCode(item_seat.seatcode, item_seat.x, item_seat.y, array_lower_seats[0].length), 
														item_seat.seatcode,
														item_seat.seatid,
														item_seat.seatcode,
														item_seat.seatnumber,
														item_seat.isavailable,
														item_seat.seatdeck || null,
														`${trip_key}-${item_seat.seatid}`
													)}>
													<img src="images/ic_available.png" alt="seating"
														// id={item_seat.seatid} 
														id={`${trip_key}-${item_seat.seatid}`}
														className={Selected(item_seat.isavailable, item_seat.isaisle) + ' seat_cell'}>
													</img>
													<h6>{item_seat.seatcode
														//getSeatCode(item_seat.seatcode, item_seat.x, item_seat.y, array_lower_seats[0].length)
													}</h6>
												</a>
											</td>
										))}
									</tr>
								))}
							</table>


						</div>
					</div>
				) : (
					<div className='p-5'>
						<div className='1seat_container'>
							{`Sorry, this route is no longer available for sale. Please `}
							<a href="javascript:history.back()">navigate back to the trip listing page</a>
							{` to choose another route.`}
						</div>
					</div>
				)}

			</>
		);
	}


	//this is for double deck
	else if (dataLoad && array_upper_seats != null
		//&& array_upper_seats.length > 0 
	) {
		return (
			<>
				{loading && <div className='center'>
					<LoadingScreenModal />
				</div>}
				{error && (
					<div>{`There is a problem fetching the post data - ${error}`}</div>
				)}


				<div className='flex_display'>

					{array_upper_seats && array_upper_seats.length > 0 && (
						<div className='double_seat'>
							<p>Upper Deck</p>
							<div className={array_lower_seats.length > 0 ? 'double_seat_container' : 'double_seat_upper_container'}>
								{/* <div className='double_seat_container'> */}
								<table className='seat'>
									{array_upper_seats.map((seatRow, index) => (

										<tr key={index}>
											{seatRow.map((item_seat) => (

												<td className={corridor(item_seat.x, item_seat.y)}>
													<a
														href="javascript:void(0)"
														key={`U${trip_key}-${item_seat.seatid}`}
														value={item_seat.seatcode}
														onClick={() => selectedSeat(
															item_seat.seatcode,
															item_seat.seatid,
															item_seat.seatcode,
															item_seat.seatnumber,
															item_seat.isavailable,
															item_seat.seatdeck || null,
															`U${trip_key}-${item_seat.seatid}`
														)}
													>
														<img
															src="images/ic_available.png"
															alt="seating"
															id={`U${trip_key}-${item_seat.seatid}`}
															// id={item_seat.seatid}
															className={Selected(item_seat.isavailable, item_seat.isaisle) + ' seat_cell'}
														/>
														<h6>{item_seat.seatcode}</h6>
													</a>
												</td>
											))}
										</tr>
									))}
								</table>
							</div>
						</div>
					)}

					{array_lower_seats && array_lower_seats.length > 0 && (
						<div className='double_seat'>
							<p>Lower Deck</p>
							{/* <p className='lowerdeck_words'>Lower Deck</p> */}
							<div className='double_seat_lower_container'>
								<img className='ms-5' src="images/img_steering.png" alt="img_steering" />
								<hr />
								<table className='seat'>
									{array_lower_seats.map((seatRow, index) => (
										<tr key={index}>
											{seatRow.map((item_seat) => {
												const lower_seat_number = item_seat.seatnumber.replace(/L/g, '');
												if (lower_seat_number === '') {
													return <td />;
												} else {
													return (
														<td
														// key={item_seat.seatid}
														>
															<a
																href="javascript:void(0)"
																key={`L${trip_key}-${item_seat.seatid}`}
																value={item_seat.seatcode}
																onClick={() =>
																	selectedSeat(
																		lower_seat_number,
																		item_seat.seatid,
																		item_seat.seatcode,
																		item_seat.seatnumber,
																		item_seat.isavailable,
																		item_seat.seatdeck || null,
																		`L${trip_key}-${item_seat.seatid}`
																	)
																}
															>
																<img
																	src="images/ic_available.png"
																	alt="seating"
																	// id={item_seat.seatid}
																	// id={`L${item_seat.seatid}`}
																	id={`L${trip_key}-${item_seat.seatid}`}
																	className={Selected(item_seat.isavailable, item_seat.isaisle) + ' seat_cell'}
																/>
																<h6>{lower_seat_number}</h6>
															</a>
														</td>
													);
												}
											})}
										</tr>
									))}
								</table>
							</div>
						</div>
					)}

				</div>
			</>
		);
	}
	else {
		return (
			<>
				<div className='center'>
					<LoadingScreenModal />
				</div>
			</>
		)
	}
}

